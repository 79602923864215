import Starter from '../pages/_Starter/_Starter';
import { lazy } from 'react';

export const routes = [
  {
    label: 'Home',
    to: '/Home',
    needsAuth: false,
    nav: true,
    component: getPage('_Starter'),
  },
  {
    label: 'Game',
    to: '/game/:gameId',
    nav: false,
    component: getPage('Game'),
  },
  {
    label: 'Home',
    to: '/',
    nav: true,
    component: getPage('Home'),
  },
  { label: 'Auth', to: '/auth', nav: false, component: getPage('Auth') },
].map((route) => {
  const component = route.component ? route.component : Starter;
  // to is what things link to, and path is what the route will accepted.
  // this will normally be the same.
  const path = route.path ? route.path : route.to;
  const pageId = pageIdFromPath(path);
  return { ...route, component, path, pageId };
});

function getPage(pageName) {
  return lazy(() => import(`../pages/${pageName}/${pageName}`));
}

function pageIdFromPath(path) {
  if (path[0] === '/') {
    path = path.substr(1);
  }
  return path.split('/')[0];
}
