import React from 'react';

export default function NotFound({ label, pageId, path }) {
  return (
    <div>
      <p>No matching page found.</p>
      <p>pageId: {pageId}</p>
      <p>path: {path}</p>
    </div>
  );
}
