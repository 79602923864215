import { connect } from 'react-redux';
import { setKeyVal } from './store/actions';

export default function KeyVal(PassedComponent) {
  const mapStateToProps = (state) => ({
    keyVal: state.keyVal,
  });
  const mapDispatchToProps = (dispatch) => ({
    setKeyVal: (key, val) => dispatch(setKeyVal(key, val)),
  });
  return connect(mapStateToProps, mapDispatchToProps)(PassedComponent);
}
