import './App.scss';

import React, { Suspense } from 'react';

import { Grommet } from 'grommet';
import NotFound from './pages/NotFound/NotFound';
import { Router } from '@reach/router';
import { createRoute } from './components/RoutingAndNav';
import { routes } from './constants/routes';

const theme = {
  global: {},
};

export default function App() {
  const isLoggedIn = false; //we could add login testing here
  return (
    <Grommet theme={theme} full id="main">
      <Main isLoggedIn={isLoggedIn} />
    </Grommet>
  );
}

const Main = ({ isLoggedIn }) => {
  return (
    <>
      <div id="main">
        <Suspense fallback={<p>loading...</p>}>
          <Router>
            {routes.map((route) => {
              return createRoute({ route, isLoggedIn });
            })}

            <NotFound default />
          </Router>
        </Suspense>
      </div>
    </>
  );
};
