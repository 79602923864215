import { Link } from '@reach/router';
import React from 'react';

export function Nav({ routes, isLoggedIn }) {
  return (
    <div id="nav">
      <ul>
        {routes
          .filter(({ nav }) => !!nav)
          .map((route) => renderRouteLink({ route, isLoggedIn }))}
      </ul>
    </div>
  );
}

export function createRoute({ route, isLoggedIn }) {
  if (!isRouteAllowed({ route, isLoggedIn })) {
    return null;
  }
  const Component = route.component;
  const path = route.path;
  const props = {
    isLoggedIn,
    ...route,
  };
  if (!path || !Component) {
    return;
  }
  return <Component key={route.path} {...props}></Component>;
}

function renderRouteLink({ route, isLoggedIn }) {
  if (!isRouteAllowed({ route, isLoggedIn })) {
    return null;
  }
  const { label, to, icon } = route;
  return (
    <li key={label}>
      <Link to={to}>
        <span className={icon}></span>
        {label}
      </Link>
    </li>
  );
}

function isRouteAllowed({ route, isLoggedIn }) {
  if (
    (route.needsAuth && !isLoggedIn) ||
    process.env[`REACT_APP_SKIP_${route.pageId.toUpperCase()}`]
  ) {
    return false;
  }
  return true;
}
