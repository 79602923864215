const keyVal = (state = {}, action) => {
  const newState = { ...state };
  switch (action.type) {
    case 'SET_KEY_VALUE':
      newState[action.key] = action.val;
      return newState;
    default:
      return state;
  }
};
export default keyVal;
