import KeyVal from '../../components/KeyVal/KeyVal';
import React from 'react';
export function Starter({ label, pageId, path, setKeyVal, keyVal }) {
  return (
    <div className={'page'} id={`page-${pageId}`}>
      <h2>{label}</h2>
      <p>
        The page at path <strong>{path}</strong> hasn't been implemented yet.{' '}
      </p>
    </div>
  );
}

export default KeyVal(Starter);
